import React from 'react';

import OpportunityFormMain from '../../components/dashboard/opportunityForm/opportunityFormMain';
import DashboardLayout from '../../components/global/dashboard/dashboardLayout/dashboardLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, DashboardPageType } from '../../types/auth';
import { formTypes } from '../../types/forms';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';

type DashboardOpportunityTranslateContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const DashboardOpportunityTranslate: React.FC<{
  id: number;
  pageContext: DashboardOpportunityTranslateContext;
}> = ({ id, pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    let slug = i18n.t('account.opportunity.translate.slug', l);
    slug = slug ? slug : 'account/opportunity/translate';

    localizationsData.push({
      attributes: {
        slug: `${slug}/${id}`,
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    dashboardPageType: DashboardPageType.translateOpportunity,
    manageOpportunitiesPage: true,
    pageEntityId: id,
    attributes: {
      slug: `${slug}/${id}`,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <DashboardLayout>
        <OpportunityFormMain type={formTypes.translate} />
      </DashboardLayout>
    </AuthPageBase>
  );
};

export default DashboardOpportunityTranslate;
